.styleFooter
{
	background: '-webkit-linear-gradient(top, #203586 0%, #112059 100%)';
}

.footer {
  	background: -webkit-linear-gradient(top, #203586 0%, #112059 100%);
    bottom:0;
    width: 100%;
    padding: 10px 15px;
    color:white;
    text-align:center;
    margin-top: 30px;
}

.footer a{
	color: #a0eef5;
    outline: none;
    text-decoration: none;
}

.footerText
{
	margin: 2px 0px;
    color: #a0eef5;
    font-family: Georgia, Palatino, Times, serif;
    font-size: 12px;
    line-height: 16px;
    font-weight: normal;
    text-align: center;
}