.ui.button.buttonStyle {
  display: block;
  width: 100%;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33;
  border-radius: 6px;
}

.ui.input.inputStyle input {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33;
  border-radius: 6px;
  width: 100%;
  font-family: "Courier New", Courier, monospace;
  border: 1px solid #ccc;
}
.login .column {
  max-width: 340px;
}

.login .field {
  width: 100%;
}

.login .title {
  max-width: initial;
}

.ui.header.formTitleStyle {
  font-size: 24px;
  font-weight: 500;
  color: #203586;
  font-family: Georgia, Palatino, Times, serif;
  text-align: left;
}

.ui.form.formStyle {
  padding-top: 1em;
}

.loginError {
  font-size: 18px;
  background-color: black;
  white-space: pre-line;
  border-radius: 6px;
  background-color: #f2dede;
  border-color: #ebccd1;
  color: #a94442;
  border: 1px solid transparent;
  text-align: center;
}

.loginInfo {
  font-size: 18px;
  background-color: black;
  white-space: pre-line;
  border-radius: 6px;
  background-color: #e4f2de;
  border-color: #ccebd3;
  color: #42a94b;
  border: 1px solid transparent;
  text-align: center;
}

.ui.grid.centered {
  margin-bottom: 50px;
}
