.table {
  margin-top: 2%;
  width: 75%;
  font-size: 16px;
  margin-left: 12.5%;
}

.titleDiv {
  margin: 40px 0 20px;
}

.tableTitle {
  font-size: 30px;
  color: #203586;
  font-weight: normal;
}

.buttonDiv {
  text-align: right;
  padding-top: 10px;
  margin-right: 12.5%;
}

.buttonHome {
  color: black;
}

.updateError {
  font-size: 18px;
  background-color: black;
  white-space: pre-line;
  border-radius: 6px;
  background-color: #f2dede;
  border-color: #ebccd1;
  color: #a94442;
  border: 1px solid transparent;
  text-align: center;
}

.updateInfo {
  font-size: 18px;
  background-color: black;
  white-space: pre-line;
  border-radius: 6px;
  background-color: #e4f2de;
  border-color: #ccebd3;
  color: #42a94b;
  border: 1px solid transparent;
  text-align: center;
}

.profileForm {
  text-align: left;
}

.profileColumn {
  width: auto !important;
}

.monoFont input[type="text"] {
  font-family: monospace !important;
}
