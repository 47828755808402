#root {
  /* My flex'd children's flow will relate to columns, not rows. */
  display: flex;
  flex-flow: column;
  /* Make sure I occupy at minimum 100% of the screen so my children can too. */
  min-height: 100%;
}

.divCenter {
  display: flex;
  align-items: flex-start;
  justify-content: center;

  /* My contents fill the page */
  flex: 1;
  overflow: auto;
}

.globalComponentDiv {
  flex: 1;
}

.ui.header.titleStyle {
  font-size: 30px;
  font-weight: 500;
  color: #203586;
  font-family: Georgia, Palatino, Times, serif;
  padding-top: 1em;
}

.ui.form input{
  border: 1px solid #7D7675 !important;
}

::placeholder { 
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: gray !important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet 
  Explorer 10-11 */
  color: gray !important;
}