.headerBackground {
  border-top: 2px solid #cba45f;
  background: -webkit-linear-gradient(top, #203586 0%, #112059 100%);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.logoutPosition {
  font-size: 18px;
  line-height: 20px;
  height: 50px;
  color: white;
}

.header1 {
  font-size: 18px;
  line-height: 20px;
  height: 50px;
  color: white;
  padding-top: 5px;
  padding-left: 5px;
  padding-bottom: 5px;
}

.header2 {
  font-size: 0.7em;
  font-variant: small-caps;
}

.foundedLeft {
  position: absolute;
  top: 0px;
  right: 146px;
  border-top: 12px solid #cba45f;
  border-left: 12px solid transparent;
  line-height: 12px;
}

.foundedRight {
  position: absolute;
  top: 0px;
  right: 60px;
  border-top: 12px solid #cba45f;
  border-right: 12px solid transparent;
  line-height: 12px;
}

.foundedCenter {
  position: absolute;
  top: 0px;
  right: 72px;
  width: 74px;
  font-family: Sans-serif;
  font-size: 8px;
  line-height: 12px;
  background-color: #cba45f;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
}

.buttonLogout {
  right: 69px;
  top: 15px;
}

.buttonHelp {
  right: 150px;
  top: 15px;
}

.buttonProfile {
  right: 190px;
  top: 15px;
}

.buttonAdmin {
  right: 230px;
  top: 15px;
}

.buttonHome {
  right: 270px;
  top: 15px;
}

.buttonDropdown {
  position: absolute;
  right: 87px;
  top: 15px;
}

.buttonDiv {
  margin-right: 0px !important;
  padding-top: 15px !important;
  padding-right: 63px !important;
}

.modalHelpOuter {
  height: 90%;
}

.modalHelpContent {
  height: 90%;
}

.modalHelpDescription {
  height: 100%;
}
